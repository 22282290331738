@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body{
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden!important;
}
.App{
  overflow-x: hidden;
}
.fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.5s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.top{
    transform: translateY(20vh);
  }
  .fade-in-section.bottom{
    transform: translateY(-20vh);
  }
  .fade-in-section.right{
    transform: translateX(-20vh);
  }
  .fade-in-section.left{
    transform: translateX(20vh);
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }