@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body{
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden!important;
}
.App{
  overflow-x: hidden;
}
.fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease-out, -webkit-transform 1.5s ease-out;
    transition: opacity 0.6s ease-out, transform 1.5s ease-out;
    transition: opacity 0.6s ease-out, transform 1.5s ease-out, -webkit-transform 1.5s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.top{
    -webkit-transform: translateY(20vh);
            transform: translateY(20vh);
  }
  .fade-in-section.bottom{
    -webkit-transform: translateY(-20vh);
            transform: translateY(-20vh);
  }
  .fade-in-section.right{
    -webkit-transform: translateX(-20vh);
            transform: translateX(-20vh);
  }
  .fade-in-section.left{
    -webkit-transform: translateX(20vh);
            transform: translateX(20vh);
  }
  .fade-in-section.is-visible {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
/* body {
    margin: 0 !important;
    padding: 0 !important;
    background: white !important;
    font-family: Metropolis, sans-serif !important;
    font-weight: 300 !important;
    line-height: 1.6 !important;
    font-size: 16px !important;
    color: #556575 !important;
}

.custom-switch {
    padding: 0 !important;
}

h2 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    color: #1d2b36 !important;
    font-size: 36px !important;
    line-height: 1.25 !important;
    font-weight: 600 !important;
}

h3 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    color: #1d2b36 !important;
    font-size: 23px !important;
    line-height: 1.35 !important;
    font-weight: 500 !important;
}

h4 {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    color: #1d2b36 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    font-weight: 600 !important;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    border: 0;
}

a {
    text-decoration: none !important;
}

a:hover {
    color: inherit !important;
    text-decoration: none !important;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

textarea {
    overflow: auto;
} */


